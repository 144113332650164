
import { Component, Vue } from 'vue-property-decorator';
import MessageBlockBtn from '@/components/UI/MessageBlock/MessageBlockBtn.vue';
import MessageBlockModal from '@/components/UI/MessageBlock/MessageBlockModal.vue';

@Component({
  components: { MessageBlockBtn, MessageBlockModal }
})
export default class MessageBtn extends Vue {
  private showModal = false;
}
