
import { Component, Prop, Vue } from 'vue-property-decorator';
import { PlusIcon } from '@/components/UI/icons';
import { EditIcon } from '@/components/UI/icons';
import { CrossIcon } from '@/components/UI/icons';

@Component({
  components: {
    PlusIcon, 
    EditIcon,
    CrossIcon,
  }
})
export default class WButton extends Vue {
  @Prop() icon!: string;
  @Prop({ default: 'primary' }) type!: 'primary' | 'default' | 'transparent';
}
