
import { Component, Vue, Watch } from 'vue-property-decorator';
import { ArrowLeftIcon } from '@/components/UI/icons';
import routesList from './routesList';

@Component({
  components: { ArrowLeftIcon }
})
export default class Breadcrumbs extends Vue {
  private breadcrumbs: Array<string> = [];
  private routesList = routesList;

  @Watch('$route.fullPath')
  updateBreadcrumbs(): void {
    this.breadcrumbs = [];
    if (this.$route.fullPath === '/') {
      this.breadcrumbs = ['home'];
      return;
    }
    this.breadcrumbs = this.$route.fullPath.substring(1).split('/');
    this.breadcrumbs && this.breadcrumbs.splice(1, 0, '')
  }

  created(): void {
    this.updateBreadcrumbs();
  }
}
