
import { Component, Prop, Vue } from 'vue-property-decorator';
import { CloseIcon } from '@/components/UI/icons';

@Component({
  components: { CloseIcon }
})
export default class MessageBlockModal extends Vue {
  @Prop() show!: boolean;
}
