
import { Component, Vue } from 'vue-property-decorator';
import { MessageIcon } from '@/components/UI/icons';

@Component({
  components: { MessageIcon }
})
export default class MessageBlockBtn extends Vue {
  private timeout: ReturnType<typeof setTimeout> | null = null;
  private showNotification = false;

  created(): void {
    this.timeout = setTimeout(() => {
      this.showNotification = true;
    }, 2000);
  }
}
