
import { Component, Vue } from 'vue-property-decorator';
import {
  CartIcon,
  ChartIcon,
  ExitIcon,
  HomeIcon,
  PersonIcon,
  QuestionIcon,
  SpeakerIcon,
  SupportIcon,
  WalletIcon,
} from '@/components/UI/icons/index';
import WButton from "@/components/UI/WButton.vue";

@Component({
  components: {
    CartIcon,
    ChartIcon,
    ExitIcon,
    HomeIcon,
    PersonIcon,
    QuestionIcon,
    SpeakerIcon,
    SupportIcon,
    WalletIcon,
    WButton,
  }
})
export default class SidebarNav extends Vue {
  private menuItems = [
    { id: 0, name: 'Главная', path: '/', icon: 'HomeIcon' },
    { id: 1, name: 'Мои магазины', path: '/home/my-markets', icon: 'CartIcon' },
    { id: 2, name: 'Промоматериалы', path: '/home/promo', icon: 'SpeakerIcon' },
    { id: 3, name: 'Статистика', path: '/home/statistic', icon: 'ChartIcon' },
    { id: 4, name: 'Баланс', path: '/home/balance', icon: 'WalletIcon' },
    { id: 5, name: 'Персональные данные', path: '/home/personal-data', icon: 'PersonIcon' },
    { id: 6, name: 'FAQ', path: '/home/faq', icon: 'QuestionIcon' },
    { id: 7, name: 'Техподдержка', path: '/home/support', icon: 'SupportIcon' },
    { id: 8, name: 'Выход', path: '/exit', icon: 'ExitIcon' },
  ];
  private showSidebar = false;
  private isMobile = false;

  get typeIcon(): string {
    return !this.showSidebar ? 'chevron-right' : 'chevron-left';
  }

  toggleMenu(): void {
    this.showSidebar = !this.showSidebar;
  }

  checkScreenSize(): void {
    if (window.innerWidth < 1140) {
      this.isMobile = true;
      this.showSidebar = false;
    } else {
      this.isMobile = false;
      this.showSidebar = true;
    }
  }

  created(e: any): void {
    this.checkScreenSize();

    window.addEventListener("resize", (event: any) => {
      this.checkScreenSize();
    });
  }
}
